<template>
  <ModalWrapper @close="closeAction" :isFoot="false">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <h1 class="title">Оценили</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="flex ggap-20 p-20 w-50vw fw">
        <a
          :href="profileLink + item.id"
          class="liked-item__block flex fd-c ai-c jc-c ggap-10"
          v-for="item in currentOptions"
          :key="item.id"
        >
          <img :src="item.avatar" class="liked-user__photo" />
          <p class="tac">{{ item?.fullname }}</p>
        </a>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { defineEmits, defineProps, toRefs, inject } from 'vue'
import { ModalWrapper } from '@/components'

const profileLink = inject('profileLink')

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  currentOptions: {
    type: Object,
    default: () => {}
  }
})

// Data
momentDurationFormatSetup(moment)
const { currentOptions } = toRefs(props)

// Functions
function closeAction() {
  emits('close')
}
</script>

<style scoped>
.liked-item__block {
  width: calc(20% - 20px);
}
.liked-user__photo {
  border-radius: 50%;
  width: 100%;
}
</style>
